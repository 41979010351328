body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color:#eaf3f9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-200 {
    animation-delay: 0.3s;
  }
  .animation-delay-400 {
    animation-delay: 0.4s;
  }
}

.circle {
  @apply w-5 h-5 my-12 mx-1 bg-teal-500 rounded-full;
}

.story-player-container amp-story-player {
  width: 100% !important;
  height: 100vh !important;
} 


.item-card {
  width: 280px;
  height: 320px;
  position: absolute;
  overflow: hidden;
  box-shadow: 5px 6px 10px 0 rgb(0 0 0 / 47%);
  transition: .2s;
  max-width: 320px;
}

.item-card .card-inner .card-image {
    background: rgb(113, 113, 113);
}

.item-card .img-overlay {
    background:rgba(0,0,0,.6);
    transition: all .4s ease-in-out 0s;   
    opacity: 0;
}

.item-card:hover .img-overlay {
  opacity: 0.25;
}

.item-card .card-text {
  background: linear-gradient(to top,rgba(33,33,33,.8),rgba(33,33,33,0));
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
  max-height: 100%;
  padding: 0 12px 12px;
}

.item-card .overlay-icon {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: all .1s ease-in-out 0s;
  z-index: 112;
} 

.item-card:hover .overlay-icon {
  opacity: 1;
}
.item-card .story-title {
  font-family: 'Roboto'
}

.story-model{
  z-index: 99999;
}

.page-disabled {
  opacity: 0.5;
}

.custom-next {
  height: 46px;
}

.break {
  height: 46px !important; 
}

.active-page {
  background-color: #1338a0 !important;
  color: white !important;
}

.active-page:hover {
  background-color: #1338a0 !important;
}

@media screen and (max-width: 600px) {
  .grid-item {
    width: 100%;
    max-width: 100%;
  }
  .item-card {
    width: 100%;
    max-width: 100%;
  }
  .cleverpush-story-pagination {
    margin-top: 1rem;
  }

}

@media only screen and (min-device-width: 601px)  and (max-device-width: 1024px) {
  .grid-item {
    width: 100%;
    max-width: 100%;
  }
  .item-card {
    width: 100%;
    max-width: 100%;
  }

}
